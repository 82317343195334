import { Component, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgForm } from '@angular/forms';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Router } from '@angular/router';
import { HomeService } from '../home/home.service';
import { DISABLED } from '@angular/forms/src/model';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  toasterconfig: any;

  constructor(private profileService: ProfileService, private spinnerService: Ng4LoadingSpinnerService
    , private toasterService: ToasterService, private router: Router, private homeService: HomeService) {
    this.isChangePwdBtnEnable = false;
    //  this.toasterService = toasterService;
  }

  isDisableFormFeilds: boolean = false;
  isVisible: boolean = false;
  profileSummary: any;
  glId: any;
  empId: any;
  fatherOrHusbandName: any;
  memberGender: any;
  annualIncome: any;
  department: any;
  address: any;
  mobileNumber: any;
  status: any;
  memberName: any;
  designation: any;
  email: any;
  memberAge: any;
  primaryIdType: any;
  primaryIdNumber: any;
  secIdType: any;
  secIdNumber: any;
  changePWD: any = {};
  changePassword: any;
  isChangePwdBtnEnable: boolean;
  isMaxLengthReached: boolean = false;
  MaxLengthReached: boolean = false;
  public config: ToasterConfig = new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: false,
      timeout: 0,
      positionClass: 'toast-top-center',
    });

  ngOnInit() {
    this.spinnerService.show();
    this.profileService.getProfileSummary().subscribe(data => {
      this.spinnerService.hide();
      if (data.isSuccess) {
        const object = JSON.parse(data.value);
        this.glId = object.glId;
        this.empId = object.empId;
        this.fatherOrHusbandName = object.fatherOrHusbandName;
        this.designation = object.occupation;
        this.memberGender = object.memberGender;
        this.annualIncome = object.annualIncome;
        this.department = object.department;
        this.mobileNumber = object.mobileNumber;
        this.address = object.address;
        this.status = object.memberStatus;
        this.memberName = object.memberName;
        this.email = object.email;
        this.memberAge = object.memberAge;
        this.primaryIdType = object.primaryIDCardType;
        this.primaryIdNumber = object.primaryIDCardNumber;
        this.secIdNumber = object.secondaryIDCardNumber;
        this.secIdType = object.secondaryIDCardType;
      }
    });

  }

  saveNewPassword(changePWD: NgForm) {
    this.profileService.changePassword(changePWD.value.newPassword, changePWD.value.oldPassword)
      .subscribe(data => {
        if (data.status === 'SUCCESS') {
          changePWD.resetForm();
          this.homeService.setUserLoggedOut();
          this.popToast(data.value);
          this.isDisableFormFeilds = true;
        } else {
          this.popToaster(data.errorDescription);
        }
      });

  }

  popToast(message: string) {
    const toast: Toast = {
      type: 'success',
      body: message,
      showCloseButton: true,
      clickHandler: (t, isClosed): boolean => {
        if (isClosed) {
          this.router.navigate(['home']);
        }
        return false; // remove this toast !
      }
    };
    this.toasterService.pop(toast);
  }
  popToaster(message: string) {
    const toast: Toast = {
      type: 'error',
      body: message,
      timeout: 3000
    };
    this.toasterService.pop(toast);
  }

  showDiv() {
    this.isVisible = true;
  }
  isMaxLengthReched(password: any) {
    const pass = this.changePWD[password.name];
    if (pass.length >= 20) {
      this.isMaxLengthReached = true;
    } else {
      this.isMaxLengthReached = false;
    }
  }

  MaxLengthReched(password: any) {
    const pass = this.changePWD[password.name];
    if (pass.length >= 20) {
      this.MaxLengthReached = true;
    } else {
      this.MaxLengthReached = false;
    }
  }

}
