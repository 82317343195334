import { Component, OnInit, TemplateRef } from '@angular/core';
import { HomeService } from './home.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../config/app.config';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public user_idd: any;
  public modalRef: BsModalRef;
  customerLogin = true;
  password = true;
  isValidFormSubmitted: boolean;
  login: any;
  public myInterval = 1500;
  public slides: any[] = [];
  public popups: any[]=[];
  public activeSlideIndex = 0;
  public noWrapSlides = false;
  errorMessage: string;
  invalidMsg = false;
  tenantCopyRight: string;
  vendorCopyRight: string;
  contactus: string;
  headerLeftImg: string;
  headerLeftImgWidth: number;
  launchBanner: any;
  headerRightImg: string = '';
  apkDownloadLink:any;
  constructor(private loginService: HomeService, private router: Router,
    private spinnerService: Ng4LoadingSpinnerService, private appConfig: AppConfigService,
    private modalService: BsModalService) {
    this.login = {};
    this.launchBanner = false;
  }

  ngOnInit() {
    this.tenantCopyRight = this.appConfig.getTenantCopyRight();
    this.vendorCopyRight = this.appConfig.getVendorCopyRight();
    this.contactus = this.appConfig.getContactus();
    this.headerLeftImgWidth = this.appConfig.getLeftImgWidth();
    this.headerRightImg = this.appConfig.getHeaderRightImg();
    this.headerLeftImg = this.appConfig.getHeaderLeftImg();
    this.apkDownloadLink=this.appConfig.getAPkDownloadLink();
    this.appConfig.apiCall().subscribe(data => {
      if (data != undefined && data.success && data.value != undefined) {
        var validSlides: Array<any> = [];
        var validPopups: Array<any> = [];
        if (data.value.SLIDES != undefined && data.value.SLIDES != "") {
          var slides = JSON.parse(data.value.SLIDES);
          slides.forEach(object => {
            if (object.enabled) {
              var slideObj = {};
              slideObj['image'] = object.imageURL;
              validSlides.push(slideObj);
            }
          });
        }
        this.slides=validSlides;
        if (data.value.POPUPS != undefined && data.value.POPUPS != "") {
          var popups = JSON.parse(data.value.POPUPS);
          popups.forEach(object => {
            if (object.enabled) {
               this.launchBanner = true;
               validPopups.push(object);
            }
          });
        }
        this.popups = validPopups;
      }
    });
  }

  errorMsg() {
    this.invalidMsg = false;
  }

  cancelLaunch() {
    this.modalRef.hide();
  }

  public openModal(template: TemplateRef<any>) {
    if (this.launchBanner) {
      this.launchBanner = false;
      this.modalRef = this.modalService.show(template, { class: 'modal-sm-launch' });
    }
  }

  userLogin(loginData: NgForm): void {
    this.isValidFormSubmitted = false;
    this.spinnerService.show();
    this.loginService.loginUser(loginData.value.user_id, loginData.value.password).subscribe(data => {
      this.spinnerService.hide();
      if (data.token != null) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', data.user.username);
        this.loginService.setUserLoggedIn();
        this.spinnerService.show();
        this.loginService.getNavItems().subscribe(data => {
          this.spinnerService.hide();
          if (data.success) {
            this.router.navigate([JSON.parse(data.value)[0].url]);
          }
        });
        this.spinnerService.hide();
      } else {
        this.errorMessage = data.message;
        this.invalidMsg = true;
      }
      this.customerLogin = true;
    });
    if (loginData.invalid) {
      return;
    }
  }

}

