import * as core from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { HomeService } from '../home/home.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@core.Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements core.OnInit {

  public p: any;
  public shareCapitalWQVO: any;
  public thriftBalanceWQVO: any;
  public savingAmountWQVO: any;
  public fixedAmountWQVO: any;
  public recurringAmountWQVO: any;
  public sdlockerAmountWQVO: any;
  public emiLoanAmountWQVO: any;
  public depositLoanWQVO: any;
  public suretyTaken: any[];
  public suretyGiven: any[];
  public monthlyRecovery: any[];
  itemsPerPage: number;
  constructor(private profileService: ProfileService,
    private homeService: HomeService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit(): void {
    this.itemsPerPage = 5;
    this.profileService.getProfileSummary().subscribe(data => {
      if (data.isSuccess) {
        const object = JSON.parse(data.value);
        localStorage.setItem('currentUser', object.memberName);
      }

    });

    this.spinnerService.show();
    this.homeService.getDashboardSummary().subscribe(data => {
      this.spinnerService.hide();
      if (data.isSuccess) {
        const object = JSON.parse(data.value);
        this.shareCapitalWQVO = object.SHARE.totalAmount;
        this.thriftBalanceWQVO = object.THRIFT.totalAmount;
        this.savingAmountWQVO = object.SB.totalAmount;
        this.fixedAmountWQVO = object.FD.totalAmount;
        this.recurringAmountWQVO = object.RD.totalAmount;
        this.sdlockerAmountWQVO = object.SDL.totalAmount;
        this.emiLoanAmountWQVO = object.EMILOANS.totalAmount;
        this.depositLoanWQVO = object.DL.totalAmount;
        this.suretyGiven = object.SURETY_GIVEN_TO;
        this.suretyTaken = object.SURETY_TAKEN_FROM;
        this.monthlyRecovery = object.MONTHLY_RECOVERY;
      }
    });

  }
}
