import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { HomeService } from '../home/home.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss']
})
export class DynamicDashboardComponent implements OnInit {

  public loanWidgets: any;
  public depositWidgets: any;
  public summaryObject: any;
  public suretyTaken: any[];
  public suretyGiven: any[];
  public monthlyRecovery: any[];
  itemsPerPage: number;
  constructor(private profileService: ProfileService,
    private homeService: HomeService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit(): void {
    this.itemsPerPage = 5;
    this.suretyGiven = [];
    this.suretyTaken = [];
    this.monthlyRecovery = [];
    this.profileService.getProfileSummary().subscribe(data => {
      if (data.isSuccess) {
        const object = JSON.parse(data.value);
        localStorage.setItem('currentUser', object.memberName);
      }
    });

    this.spinnerService.show();
    this.homeService.getDashboardSummary().subscribe(summaryData => {
      if (summaryData.isSuccess) {
        this.summaryObject = JSON.parse(summaryData.value);
        this.homeService.getDynamicDashboardJson().subscribe(data => {
          this.spinnerService.hide();
          if (data.success && data.value != undefined) {
            let depositWidgets = JSON.parse(data.value.DASHBORAD_LEFT_WIDGETS);
            let loanWidgets = JSON.parse(data.value.DASHBORAD_RIGHT_WIDGETS);
            depositWidgets.forEach(object => {
              let lookup: any[] = object.amountLookUpPath.split('.');
              object.balance = this.summaryObject[lookup[0]][lookup[1]];
            });
            loanWidgets.forEach(object => {
              let lookup: any[] = object.amountLookUpPath.split('.');
              object.balance = this.summaryObject[lookup[0]][lookup[1]];
            });
            this.loanWidgets = loanWidgets;
            this.depositWidgets = depositWidgets;
            this.suretyGiven = this.summaryObject.SURETY_GIVEN_TO;
            this.suretyTaken = this.summaryObject.SURETY_TAKEN_FROM;
            this.monthlyRecovery = this.summaryObject.MONTHLY_RECOVERY;
          }
        });
      }
      this.spinnerService.hide();
    });
  }
}

