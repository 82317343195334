import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { resturlConstants } from '../../rest-url/resturlconstants';


@Injectable()
export class HomeService {

  public isUserLoggedIn: any;
  public memberName: any;

  constructor(private http: HttpClient) {
    this.isUserLoggedIn = false;
  }

  setUserLoggedIn() {
    this.isUserLoggedIn = true;
  }

  setUserLoggedOut() {
    this.isUserLoggedIn = false;
  }
  setUserName(userName: string) {
    this.memberName = userName;
  }

  getUserLoggedIn() {
    return this.isUserLoggedIn;
  }
  getAuthHeader(): string {
    return 'Bearer ' + localStorage.getItem('token');
  }

  loginUser(userId: string, password: string): Observable<any> {
    return this.http.post<any>(resturlConstants.loginUrl, {
      "username": userId,
      "password": password,
      "clientShortCode": environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      },
    );
  }


  getDashboardSummary(): Observable<any> {
    return this.http.post<any>(resturlConstants.dashboardUrl, {
      'userId': localStorage.getItem('userId'),
      'clientShortCode': environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
          .set('Authorization', this.getAuthHeader())
          .set('content-type', 'application/json')
      }
    );

  }

  getNavItems() {
    return this.http.post<any>(resturlConstants.navItems, {
      'userId': localStorage.getItem('userId'),
      'clientShortCode': environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
          .set('Authorization', this.getAuthHeader())
          .set('content-type', 'application/json')
      }
    );
  }

  getDynamicDashboardJson(): Observable<any> {
    return this.http.post<any>(resturlConstants.dynamicDashboarJsondUrl, {
      'userId': localStorage.getItem('userId'),
      'clientShortCode': environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
          .set('Authorization', this.getAuthHeader())
          .set('content-type', 'application/json')
      }
    );

  }

  generateOtp(userId: string): Observable<any> {
    let sessionUserId = localStorage.getItem('userId');
    if (userId != undefined)
      sessionUserId = userId;
    return this.http.get<any>(environment.url + '/GetOTP?glid=' + sessionUserId
      + '&clientShortCode=' + environment.clientShortCode);
  }


}
