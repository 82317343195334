import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-existed-user',
  templateUrl: './existed-user.component.html',
  styleUrls: ['./existed-user.component.scss']
})
export class ExistedUserComponent implements OnInit {
  public slides: any[] = [];
  glidPattern = '^[A-Z0-9-]*$';
  mobnumPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  public changePwd: any;
  isValidFormSubmitted: boolean;

  constructor() {
    this.changePwd = {};
   }

  ngOnInit() {
    if (environment.clientShortCode == 'BHEL') {
      this.slides = [
        { image: '../assets/img/banners/BHEL-BANNER.jpg' },
        { image: '../assets/img/banners/bg2.jpg' },
      ];
    } else if (environment.clientShortCode == 'DEMO') {
      this.slides = [
        { image: '../assets/img/banners/cbs-bg1.jpg' },
        { image: '../assets/img/banners/cbs-bg2.jpg' }
      ];
    }
  }

  saveExistedUser(existedUserForm: NgForm): void {  
    this.isValidFormSubmitted = false;
    if (existedUserForm.invalid) {
      return;
    }
  }
}
