import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class NewUserService {

  constructor(private http: HttpClient) { }


  newUserRegistration(userId: string, password: string, emailId: string): Observable<any> {

    return this.http.get<any>(environment.url + '/UserRegistration?userId=' + userId
      + '&&password=' + password
      + '&&emailId=' + emailId
      + '&clientShortCode=' + environment.clientShortCode);
  }

  requestOTP(glId): Observable<any> {
    return this.http.get<any>(environment.url + '/GetOTP?glid=' + glId +
                                                '&clientShortCode=' + environment.clientShortCode);
  }

  requestOTPforNewUser(glId): Observable<any> {
return this.http.get<any>(environment.url + '/GetOTPforNewUser?glid=' + glId +
                                            '&clientShortCode=' + environment.clientShortCode);
  }

  verifyUser(glId): Observable<any> {
    return this.http.get<any>(environment.url + '/VerifyUserRegistration?userId=' + glId +
                                                  '&clientShortCode=' + environment.clientShortCode);
  }
}


