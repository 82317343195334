import { Injectable } from '@angular/core';
import { HomeService } from '../../home/home.service';
import { resturlConstants } from '../../../rest-url/resturlconstants';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable()
export class VoluntaryThriftService {
  constructor(private http: HttpClient, private homeService: HomeService) { }

  getvolunterThriftSummary(): Observable<any> {
    return this.http.post<any>(resturlConstants.voluntaryThriftUrl,
      {
        'userId': localStorage.getItem('userId'),
        'clientShortCode': environment.clientShortCode
      },
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.homeService.getAuthHeader())
      });

  }

  changeThriftAmount(voluntaryThriftAmount: string, glid: string, clientShortCode: string): Observable<any> {
    return this.http.get(environment.url + '/updateThriftTransaction?glid=' + glid
      + '&voluntaryThrift=' + voluntaryThriftAmount
      + '&clientShortCode=' + clientShortCode
      + '&thriftAmount=0',
      {
        headers: new HttpHeaders()
          .set('Authorization', this.homeService.getAuthHeader())
      });
  }

  saveForm(newThriftAmount, confirmThriftAmount, otp): Observable<any> {
    return this.http.post<any>(environment.url + '/saveForm', newThriftAmount + confirmThriftAmount + otp,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.homeService.getAuthHeader())
      });
  }

  requestOTP(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(environment.url + '/GetOTP?glid=' + localStorage.getItem('userId') + '&clientShortCode=' + environment.clientShortCode);
  }

}
