import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { HomeService } from './home/home.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private loginservice: HomeService, private router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.loginservice.isUserLoggedIn) {
      return this.loginservice.getUserLoggedIn();
    } else {
      this.router.navigate(['']);
      return this.loginservice.getUserLoggedIn();
    }
  }
}
