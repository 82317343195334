import { Injectable } from '@angular/core';
import { HomeService } from '../../home/home.service';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { resturlConstants } from '../../../rest-url/resturlconstants';
import { environment } from '../../../environments/environment';

@Injectable()

export class EmiLoansService {

  constructor(private http: HttpClient, private homeService: HomeService) { }

  getEmiSummary(): Observable<any> {
    return this.http.post<any>(resturlConstants.emiLoansUrl, {
      'userId': localStorage.getItem('userId'),
      'clientShortCode': environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.homeService.getAuthHeader())
      });

  }
}
