import { Component, Input, OnInit } from '@angular/core';
//import { navItems } from './../../_nav';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HomeService } from '../../home/home.service';
import { AppConfigService } from '../../config/app.config';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public navItems: any;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  tenantCopyRight: string;
  vendorCopyRight: string;
  public element: HTMLElement = document.body;
  public memberName: any;
  public headerLeftImg: any;
  navbarBrandLogo: any;
  constructor(private router: Router, private route: ActivatedRoute, private homeService: HomeService
    , private spinnerService: Ng4LoadingSpinnerService, private appConfig: AppConfigService) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');

    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  get user(): any {
    return localStorage.getItem('currentUser');
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  ngOnInit(): void {
    this.navbarBrandLogo = {src: this.appConfig.getHeaderLeftImg()};
    this.headerLeftImg = this.appConfig.getHeaderLeftImg();
    this.tenantCopyRight = this.appConfig.getTenantCopyRight();
    this.vendorCopyRight = this.appConfig.getVendorCopyRight();
    this.spinnerService.show();
    this.homeService.getNavItems().subscribe(data => {
      this.spinnerService.hide();
      if (data.success) {
        this.navItems = JSON.parse(data.value);
      }
    });

  }

}
