import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HomeService } from '../../home/home.service';
import { Observable } from 'rxjs';
import { resturlConstants } from '../../../rest-url/resturlconstants';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoanApplicationService {

  constructor(private http: HttpClient , private homeService: HomeService) { }

  getLoanApplication(): Observable<any> {
    return this.http.post<any>(resturlConstants.loanApplicationUrl, {
      'userId': localStorage.getItem('userId'),
      'clientShortCode': environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.homeService.getAuthHeader())
      });
  }

   validateLoanApplication(validateLoanData: object): Observable<any> {
    return this.http.post<any>(resturlConstants.validateLoanUrl , validateLoanData,
      {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.homeService.getAuthHeader())
      });
  }

  saveLoanApplication(validateLoanData: object): Observable<any> {
    return this.http.post<any>(resturlConstants.saveLoanUrl , validateLoanData,
      {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', this.homeService.getAuthHeader())
      });
   }

  getPendingLoanApp(applicationNumber: string): Observable<any> {
    return this.http.get<any>(environment.url + '/getLoanDataByAppNo?applicationNo=' + applicationNumber
    + '&clientCode=' + environment.clientShortCode,

    {
      headers: new HttpHeaders()
        .set('Authorization', this.homeService.getAuthHeader())
    });
  }
}
