import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Default Project compoents
import { DefaultLayoutComponent } from './containers';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
// import { ExistedUserComponent } from './sign-up/existed-user/existed-user.component';
import { AuthGuardService } from './auth-guard.service';
import { NewUserComponent } from './sign-up/new-user/new-user.component';
import { DynamicDashboardComponent } from './dynamic-dashboard/dynamic-dashboard.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
  },
  // {
  //   path: 'existedSignup',
  //   component: ExistedUserComponent,
  // },
  {
    path: 'newUserSignup',
    component: NewUserComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        component: DashboardComponent,
      },
      {
        path: 'dynamicDashboard',
        canActivate: [AuthGuardService],
        component: DynamicDashboardComponent,
      },
      {
        path: 'profile',
        canActivate: [AuthGuardService],
        component: ProfileComponent,
      },
      {
        path: 'dep',
        canActivate: [AuthGuardService],
        loadChildren: './deposits/deposits.module#DepositsModule'
      },
      {
        path: 'loans',
        canActivate: [AuthGuardService],
        loadChildren: './loans/loans.module#LoansModule'
      },
      {
        path: 'beneficiary',
        canActivate: [AuthGuardService],
        loadChildren: './beneficiary/beneficiary.module#BeneficiaryModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const Routing: ModuleWithProviders = RouterModule.forRoot(routes);
