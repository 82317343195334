import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HomeService } from '../home/home.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { resturlConstants } from '../../rest-url/resturlconstants';


@Injectable()
export class ProfileService {


  constructor(private http: HttpClient  , private homeService: HomeService) { }
  getProfileSummary(): Observable<any> {
    return this.http.post<any>(resturlConstants.profileUrl, {
      'userId': localStorage.getItem('userId'),
      'clientShortCode': environment.clientShortCode
    },
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.homeService.getAuthHeader())
      });
  }

  changePassword(newPassword: string, oldPassword: string): Observable<any> {
    return this.http.get<any>(environment.url + '/ChangePassword?userId=' + localStorage.getItem('userId')
    + '&newPassword=' + newPassword
    + '&oldPassword=' + oldPassword
    + '&clientCode=' + environment.clientShortCode,

    {
      headers: new HttpHeaders()
        .set('Authorization', this.homeService.getAuthHeader())
    });
  }

  }

