import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HomeService } from '../../home/home.service';
import { Observable } from 'rxjs';
import { resturlConstants } from '../../../rest-url/resturlconstants';
import { environment } from '../../../environments/environment';

@Injectable()
export class SavingDepositsService {
  constructor(private http: HttpClient, private homeService: HomeService) { }

  getSavingsSummary(): Observable<any> {

    return this.http.post<any>(resturlConstants.savingsUrl,
      {
        'userId': localStorage.getItem('userId'),
        'clientShortCode': environment.clientShortCode
      },
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.homeService.getAuthHeader())
      });

  }
}
