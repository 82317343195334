import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewUserService } from './new-user.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../config/app.config';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})

export class NewUserComponent implements OnInit {



  public slides: any[] = [];

  isValidFormSubmitted: Boolean = false;
  changePwd: any;
  errorMessage: any;
  generatedOTP: any;
  otpMessage: any;
  inputOTP: any;
  errOTP: any;
  successMessage: any;
  updatePnNumberMessage: any;
  userNotFound: any;
  userExists: any;
  emptyOTP: any;
  inputUserIdMessage: any;
  inputUserId: string;
  isReadOnly: boolean;
  tenantCopyRight: string;
  vendorCopyRight: string;
  contactus: string;
  headerLeftImg : string;
  headerLeftImgWidth:number;
  headerRightImg:string='';
  apkDownloadLink:any;
  constructor(private newUsers: NewUserService, private spinnerService: Ng4LoadingSpinnerService,private appConfig:AppConfigService) {
    this.changePwd = {};
  }

  ngOnInit() {
      this.tenantCopyRight =this.appConfig.getTenantCopyRight();
      this.vendorCopyRight = this.appConfig.getVendorCopyRight();
      this.contactus= this.appConfig.getContactus();
      this.headerLeftImgWidth=this.appConfig.getLeftImgWidth();
      this.headerRightImg=this.appConfig.getHeaderRightImg();
      this.headerLeftImg=this.appConfig.getHeaderLeftImg();
      this.apkDownloadLink=this.appConfig.getAPkDownloadLink();
      this.appConfig.apiCall().subscribe(data => {
        if (data != undefined && data.success && data.value != undefined) {
          var validSlides: Array<any> = [];
          if (data.value.SLIDES != undefined && data.value.SLIDES != "") {
            var slides = JSON.parse(data.value.SLIDES);
            slides.forEach(object => {
              if (object.enabled) {
                var slideObj = {};
                slideObj['image'] = object.imageURL;
                validSlides.push(slideObj);
              }
            });
          }
          this.slides = validSlides;
        }
      });  
  }

  saveNewUser(newUserForm: NgForm): void {  // Line 22, when form is submitted
    if (this.inputOTP !== this.generatedOTP) {
      return;
    } else {
      this.isValidFormSubmitted = false;
      this.spinnerService.show();
      this.newUsers.newUserRegistration(newUserForm.value.glId,
        newUserForm.value.password,
        newUserForm.value.emailId)
        .subscribe(data => {
          if (data.isSuccess) {
            this.successMessage = data.value;
            this.spinnerService.hide();
            setTimeout(function () { window.location.reload(); }, 2000);
          } else {
            this.errorMessage = data.errorDescription;
            this.spinnerService.hide();
          }
        });
    }
  }

  generateOTP(glId: string) {
    {
      this.spinnerService.show();
      this.verifyUser(glId);
    }
  }

  verifyUser(glId) {
    this.newUsers.verifyUser(glId).subscribe(data => {
      this.spinnerService.hide();
      if (!data.isSuccess) {
        this.otpMessage = false;
        //  this.userNotFound = false;
        this.updatePnNumberMessage = false;
        if (data.status === 'SUCCESS') {
          this.userExists = false;
          this.inputUserIdMessage = false;
          this.errOTP = false;
          this.userExists = data.value;
        } else {
          this.userNotFound = false;
          this.inputUserIdMessage = false;
          this.userExists = data.errorDescription;

        }
      } else {
        this.newUsers.requestOTPforNewUser(glId).subscribe(data_1 => {
          if (data_1.isSuccess) {
            if (data_1.value.includes('-')) {
              this.isReadOnly = true;
              this.updatePnNumberMessage = false;
              this.userNotFound = false;
              this.userExists = false;
              this.inputUserIdMessage = false;
          this.spinnerService.hide();
              this.generatedOTP = data_1.value.split('-')[0]; // locally Saving
              this.otpMessage = data_1.value.split('-')[1]; // Showing On UI
            } else {
              this.otpMessage = false;
              this.userExists = false;
              this.inputUserIdMessage = false;
          this.spinnerService.hide();
              this.updatePnNumberMessage = data_1.value; // Showing On UI
            }
          } else {
            this.userExists = false;
            this.otpMessage = false;
            this.updatePnNumberMessage = false;
            this.inputUserIdMessage = false;
          this.spinnerService.hide();
            this.userNotFound = data_1.errorDescription;
          }
        });

      }
    });
  }

  valuechange(newValue) {
    this.inputOTP = newValue.target.value;
    if (this.inputOTP !== this.generatedOTP) {
      this.emptyOTP = false;
      this.errOTP = 'Entered OTP is Not Valid';
      if (this.inputOTP === '') {
        this.errOTP = false;
        this.emptyOTP = 'Enter OTP';
      }
    } else {
      this.emptyOTP = false;
      this.errOTP = false;
    }
  }

  inputChange(changedValue) {
    this.inputUserId = changedValue.target.value;
    if (this.inputUserId === '') {
      this.otpMessage = false;
      this.userNotFound = false;
      this.userExists = false;
      this.inputUserIdMessage = 'Enter User Id';
    }
  }

  clearFrom() {
    this.updatePnNumberMessage = false;
    this.userNotFound = false;
    this.errOTP = false;
    this.emptyOTP = false;
    this.inputUserIdMessage = false;
    this.otpMessage = false;
    this.userExists = false;
    this.successMessage = false;
    this.errorMessage = false;
    this.isReadOnly = false;
    (<HTMLFormElement>document.getElementById('form')).reset();
  }
}
