import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../config/app.config';
import { HomeService } from '../home/home.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  glidPattern = '^[A-Z0-9-]*$';
  isReadOnly: boolean;
  public changePwd: any;
  isValidFormSubmitted: boolean;
  successMessage: any;
  public slides: any[] = [];
  errorMessage: any;
  forgotPasswordErrorMessage: any;
  generatedOTP: any;
  otpMessage: any;
  inputOTP: any;
  errOTP: any;
  forgotPasswordSuccessMessage: any;
  userNotFound: any;
  updatePnNumberMessage: any;
  emptyOTP: any;
  inputUserId: any;
  inputUserIdMessage: any;
  tenantCopyRight: string;
  vendorCopyRight: string;
  contactus: string;
  headerLeftImg: string;
  headerLeftImgWidth: number;
  headerRightImg: string = '';
  apkDownloadLink: any;
  constructor(private forgotPasswordService: ForgotPasswordService,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router, private appConfig: AppConfigService,private homeService:HomeService) {
    this.changePwd = {};
  }

  ngOnInit() {
    this.tenantCopyRight = this.appConfig.getTenantCopyRight();
    this.vendorCopyRight = this.appConfig.getVendorCopyRight();
    this.contactus = this.appConfig.getContactus();
    this.headerLeftImgWidth = this.appConfig.getLeftImgWidth();
    this.headerRightImg = this.appConfig.getHeaderRightImg();
    this.headerLeftImg = this.appConfig.getHeaderLeftImg();
    this.apkDownloadLink=this.appConfig.getAPkDownloadLink();
    this.appConfig.apiCall().subscribe(data => {
      if (data != undefined && data.success && data.value != undefined) {
        var validSlides: Array<any> = [];
        if (data.value.SLIDES != undefined && data.value.SLIDES != "") {
          var slides = JSON.parse(data.value.SLIDES);
          slides.forEach(object => {
            if (object.enabled) {
              var slideObj = {};
              slideObj['image'] = object.imageURL;
              validSlides.push(slideObj);
            }
          });
        }
        this.slides = validSlides;
      }
    });

  }

  generateOTP(glId: string) {
    {
      this.spinnerService.show();
      this.homeService.generateOtp(glId).subscribe(data => {
        this.spinnerService.hide();
        if (data.isSuccess) {
          if (data.value.includes('-')) {
            this.isReadOnly = true;
            this.userNotFound = false;
            this.updatePnNumberMessage = false;
            this.inputUserIdMessage = false;
            this.generatedOTP = data.value.split('-')[0]; // locally Saving
            this.otpMessage = data.value.split('-')[1]; // Showing On UI
          } else {
            this.otpMessage = false;
            this.userNotFound = false;
            this.inputUserIdMessage = false;
            this.updatePnNumberMessage = data.value; // Showing On UI
          }
        } else {
          this.otpMessage = false;
          this.updatePnNumberMessage = false;
          this.inputUserIdMessage = false;
          this.userNotFound = data.errorDescription;
        }
      });
    }
  }

  valuechange(newValue) {
    this.inputOTP = newValue.target.value;
    if (this.inputOTP !== this.generatedOTP) {
      this.emptyOTP = false;
      this.errOTP = 'Entered OTP is Not Valid';
      if (this.inputOTP === '') {
        this.errOTP = false;
        this.emptyOTP = 'Enter OTP';
      }
    } else {
      this.emptyOTP = false;
    }
  }

  inputChange(changedValue) {
    this.inputUserId = changedValue.target.value;
    if (this.inputUserId === '') {
      this.userNotFound = false;
      this.userNotFound = false;
      this.inputUserIdMessage = 'Enter User Id';
    }
  }

  changePassword(forgotPassword: NgForm): void {
    // Line 13, when form is submitted
    if (this.inputOTP !== this.generatedOTP) {
      return;
    } else {
      this.spinnerService.show();
      this.forgotPasswordService.changePassword(forgotPassword.value.glId, forgotPassword.value.confirmPassword).subscribe(data => {
        if (data.isSuccess) {
          this.forgotPasswordErrorMessage = false;
          this.forgotPasswordSuccessMessage = data.value;
          this.spinnerService.hide();
          setTimeout(() => {
            this.router.navigate(['/home']);
          }, 2000);
        } else {
          this.spinnerService.hide();
          this.forgotPasswordSuccessMessage = false;
          this.forgotPasswordErrorMessage = data.value;
        }
      });
    }
  }



  clearFrom() {
    this.errOTP = false;
    this.isReadOnly = false;
    this.userNotFound = false;
    this.updatePnNumberMessage = false;
    this.otpMessage = false;
    (<HTMLFormElement>document.getElementById('form')).reset();
  }

}
