import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { resturlConstants } from '../../rest-url/resturlconstants';
import { HomeService } from '../home/home.service';

@Injectable()
export class BeneficiaryService {

  constructor(private http: HttpClient, private homeService: HomeService) { }

  searchByIfsc(ifscCode: string): Observable<any> {
    return this.http.get<any>(environment.url + '/ifsc/' + ifscCode);
  }

  searchByBankAndBranch(bank: string, branch: string): Observable<any> {
    return this.http.get<any>(environment.url + '/ifsc/' + bank + '/' + branch);
  }

  retrieveBeneficiaryAccounts(): Observable<any> {
    return this.http.get<any>(environment.url + '/benef/' + environment.clientShortCode + '/' + localStorage.getItem('userId'));
  }

  deleteBeneficiaryAccounts(id: number): Observable<any> {
    let httpParams = new HttpParams();
    let options = { params: httpParams };
    return this.http.delete(environment.url + '/benef/' + environment.clientShortCode + '/' + localStorage.getItem('userId') + '/' + id, options);
  }

  registerBeneficiary(body: any): Observable<any> {
    return this.http.post<any>(environment.url + '/benef/' + environment.clientShortCode + '/' + localStorage.getItem('userId'), body,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', this.homeService.getAuthHeader())
      });

  }
}


