import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HomeService } from './home/home.service';
import { Location, PlatformLocation } from '@angular/common';
import { DOCUMENT } from '@angular/platform-browser';
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  isCheckLogin: any;
  public routePath: any;

  constructor(private router: Router, public loginService: HomeService, private location: PlatformLocation) {

    this.isCheckLogin = localStorage.getItem('token');

    location.onPopState(() => {
      if (this.isCheckLogin) {
        this.loginService.isUserLoggedIn = true;
        if (window.location.pathname == "/" || window.location.pathname == "") {
          this.router.navigate(['dashboard']);
        }
      } else {
        this.loginService.isUserLoggedIn = false;
      }
    });


    if (this.isCheckLogin != null) {
      this.loginService.isUserLoggedIn = true;
      if (this.isTokenExpired() == true) {
        this.router.navigate(['']);
        localStorage.clear();
        return;
      }
    } else {
      this.loginService.isUserLoggedIn = false;
    }


  }
  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = localStorage.getItem('token');
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
