import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SwiperModule, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CarouselModule} from 'ngx-bootstrap';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

const SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 3,
  keyboardControl: true
};

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewUserComponent } from './sign-up/new-user/new-user.component';
import { ExistedUserComponent } from './sign-up/existed-user/existed-user.component';
import { HomeService } from './home/home.service';
import { AuthGuardService } from './auth-guard.service';
import { SavingDepositsService } from './deposits/saving-deposits/saving-deposits.service';
import { EmiLoansService } from './loans/emi-loans/emi-loans.service';
import { ForgotPasswordService } from './forgot-password/forgot-password.service';
import { FixedDepositsService } from './deposits/fixed-deposits/fixed-deposits.service';
import { RecurringDepositsService } from './deposits/recurring-deposits/recurring-deposits.service';
import { LockersService } from './deposits/lockers/lockers.service';
import { DepositLoansService } from './loans/deposit-loans/deposit-loans.service';
import { ProfileService } from './profile/profile.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { VoluntaryThriftService } from './deposits/voluntary-thrift/voluntary-thrift.service';
import { ToasterModule, ToasterService} from 'angular2-toaster';
import { LoanApplicationService } from './loans/loan-application/loan-application.service';
import { NumberValidationDirective } from './number-validation.directive';
import { CurrentDepositsService } from './deposits/current-deposits/current-deposits.service';
import { DailyDepositsService } from './deposits/daily-deposits/daily-deposits.service';
import { DynamicDashboardComponent } from './dynamic-dashboard/dynamic-dashboard.component';
import { GoldLoansService } from './loans/gold-loans/gold-loans.service';
import { CcLoansService } from './loans/cc-loans/cc-loans.service';
import { AppConfigService } from './config/app.config';
import { BeneficiaryService } from './beneficiary/beneficiary.service';
import { NewUserService } from './sign-up/new-user/new-user.service';

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    NgxPaginationModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    SwiperModule.forRoot(SWIPER_CONFIG),
    Ng4LoadingSpinnerModule.forRoot(),
    ToasterModule,
    BrowserAnimationsModule,
    ModalModule.forRoot()

   ],

  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    DashboardComponent,
    ProfileComponent,
    HomeComponent,
    ForgotPasswordComponent,
    NewUserComponent,
    ExistedUserComponent,
    DynamicDashboardComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: PathLocationStrategy
  },
    HomeService,
    AuthGuardService,
    ForgotPasswordService,
    FixedDepositsService,
    RecurringDepositsService,
    DailyDepositsService,
    LockersService,
    SavingDepositsService,
    CurrentDepositsService,
    EmiLoansService,
    DepositLoansService,
    ProfileService,
    VoluntaryThriftService,
    ToasterService,
    LoanApplicationService,
    GoldLoansService,
    CcLoansService,
    AppConfigService,
    BeneficiaryService,
    NewUserService],


  bootstrap: [AppComponent]
})
export class AppModule { }
