import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HomeService } from "../home/home.service";

const metadata = {
    DEMO: {
        copyRight: ' SmartCBS. All rights reserved.',
        contactus: 'http://www.ykinnosoft.com/',
        headerRightImg: '',
        headerLeftImg: 'assets/img/brand/cbs-logo.png',
        headerLeftImgWidth: 220
    },
    KTPP: {
        copyRight: ' KTPP EMPLOYEES MACC SOCIETY LIMITED, CHELPUR.',
        contactus: 'https://ssp.ktppcreditsociety.com/',
        headerRightImg: '',
        headerLeftImg: 'assets/img/brand/KTPP.jpg',
        headerLeftImgWidth: 300
    },
    BHEL:{
        copyRight: ' BHEL ECCSL. All rights reserved.',
        contactus: 'https://bheleccs.in/',
        headerRightImg: 'assets/img/seal-batch.png',
        headerLeftImg: 'assets/img/brand/Main-Logo.svg',
        headerLeftImgWidth: 220
    },
    CYBPOL:{
        copyRight: ' The Police Employees Coop Credit Society LTD. Cyberabad.',
        contactus: 'https://ssp.cyberabadpolicecooperative.in/',
        headerRightImg: '',
        headerLeftImg: 'assets/img/brand/cybpol-text-logo.png',
        headerLeftImgWidth: 250
    }
}


@Injectable()
export class AppConfigService implements OnInit {

    public headerLeftImgWidth: 220;
    public vendorCopyRight: any;
    constructor(private homeService: HomeService, private http: HttpClient) {
        this.vendorCopyRight='Designed and Developed by YK Innosoft Technologies';
    }

    ngOnInit() {
        this.getDefaultMetadata();
    }

    apiCall(): Observable<any> {
        return this.http.get<any>(environment.url + '/util/SSPMetadata?client=' + environment.clientShortCode, {
            headers: new HttpHeaders()
        });
    }

    getDefaultMetadata() {
         var response = this.apiCall();
        response.subscribe(data => {
            if (data != undefined && data.success && data.value != undefined) {
                localStorage.apkDownloadLink = data.value.APK_DOWNLOAD_LINK;
                let validSlides: Array<any> = [];
                if (data.value.SLIDES != undefined && data.value.SLIDES != "") {
                    var slides = JSON.parse(data.value.SLIDES);
                    slides.forEach(object => {
                        if (object.enabled) {
                            var slideObj = {};
                            slideObj['image'] = object.imageURL;
                            validSlides.push(slideObj);
                        }
                    });
                }
                localStorage.slides = validSlides;
            } 
        });
    }

    getTenantCopyRight() {
        var year =  new Date().getFullYear();
        return '@ '+ year + this.getMetadataByClientKeyAndParam('copyRight');
    }

    getVendorCopyRight() {
        return this.vendorCopyRight;
    }

    getContactus() {
        return this.getMetadataByClientKeyAndParam('contactus');
    }

    getHeaderRightImg() {
        return this.getMetadataByClientKeyAndParam('headerRightImg');
    }

    getHeaderLeftImg() {
        return this.getMetadataByClientKeyAndParam('headerLeftImg');
    }


    getLeftImgWidth() {
       return this.getMetadataByClientKeyAndParam('headerLeftImgWidth');
    }

    getAPkDownloadLink() {
        if (localStorage.apkDownloadLink == undefined)
            this.getDefaultMetadata();
        return localStorage.apkDownloadLink;

    }

    getMetadataByClientKeyAndParam(searchKey: string){
        var clientMetadata = metadata[environment.clientShortCode];
        if(clientMetadata!=undefined){
            console.log(searchKey+" "+clientMetadata[searchKey])
            return clientMetadata[searchKey]!=null? clientMetadata[searchKey]:'';
        }
        return '';
    }
}