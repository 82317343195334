import { environment } from '../environments/environment';



export const resturlConstants = {
    loginUrl: environment.url + '/token/generate-token',
    navItems: environment.url + '/util/getSSPNavItems',
    dashboardUrl: environment.url + '/dashboardSummary',
    dynamicDashboarJsondUrl:environment.url+'/util/dynamicDashboardJson',
    savingsUrl: environment.url + '/SBDashboardSummary',
    currentUrl: environment.url + '/CDDashboardSummary',
    emiLoansUrl: environment.url + '/EMIDashboardSummary',
    gdlLoanUrl: environment.url+'/GDLDashboardSummary',
    FDUrl: environment.url + '/FDDashboardSummary',
    RDUrl: environment.url + '/RDDashboardSummary',
    DDUrl: environment.url + '/DDDashboardSummary',
    LockersUrl: environment.url + '/SDLDashboardSummary',
    depositLoansUrl:  environment.url + '/DLDashboardSummary',
    ccLoansUrl: environment.url+'/CCDashboardSummary',
    profileUrl: environment.url + '/profileSummary',
    changePasswordUrl: environment.url + '/ChangePassword',
    loanApplicationUrl: environment.url + '/emiSupportData',
    validateLoanUrl: environment.url + '/validateEMILoanApplication',
    saveLoanUrl: environment.url + '/saveEMILoanApplication',
    voluntaryThriftUrl: environment.url + '/VoluntaryThriftSummary',
    getOtpUrl: environment.url + '/GetOTP',
    pendingLoanAppUrl: environment.url + '/getLoanDataByAppNo'
  };
